import { fetchWithToken } from 'utils/fetch';
import { mapWorkspaceOffer } from 'utils/mapping';

export async function getOffers(): Promise<WorkspaceOffer[]> {
  const response = await fetchWithToken(`/listings/offers`, {
    method: 'GET'
  });
  const data = await response.json();

  const offers = [].concat(
    ...data.map(item =>
      item.offers.map(offer => ({
        listingPrice: item.current_price,
        ...mapWorkspaceOffer(offer)
      }))
    )
  );

  return offers;
}
