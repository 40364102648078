import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import styled from '@emotion/styled';
import { Icon, Image } from 'components';
import { formatCurrency } from 'utils/rifmFormatters';
import { getListingAddress } from 'utils/listing';
import useLayout from 'hooks/useLayout';
import Accordion from 'components/Accordion';
import OfferDetails from 'components/OfferDetails';
import AccordionTableItem from 'components/AccordionTableItem';
import listingNoPhoto from 'assets/images/listing_no_photo.svg';

interface OffersTableItemProps {
  data: WorkspaceOffer;
  openPopup: (value: { type: 'Accept' | 'Reject'; data: WorkspaceOffer }) => void;
}

const OffersTableItem: React.FC<OffersTableItemProps> = ({ data, ...props }) => {
  const formData = typeof data.formData === 'string' ? JSON.parse(data.formData) : data.formData;
  const isMobile = useLayout() === 'mobile';
  const hasLink = Boolean(data.link);

  return (
    <StyledAccordion
      label={
        <AccordionTableItem
          fields={[
            {
              key: 'listing',
              value: (
                <>
                  <Image
                    className="table-header-info__item-img"
                    cloudFront={formData.listing?.images?.[0]}
                    error={listingNoPhoto}
                    size="24x24"
                    alt="mini-item"
                  />
                  <span>
                    {formData.lising
                      ? getListingAddress(formData.listing)
                      : formData.propertyAddress}
                  </span>
                  {isMobile && hasLink && (
                    <a
                      href={data.link}
                      target="_blank"
                      rel="noreferrer"
                      className="download-link"
                      onClick={e => e.stopPropagation()}>
                      <Icon name="download" size={12} />
                    </a>
                  )}
                </>
              )
            },
            {
              key: 'listingPrice',
              label: 'Listing Price',
              value: formatCurrency(String(data.listingPrice))
            },
            {
              key: 'offerAmount',
              label: 'Offer Amount',
              value: formatCurrency(data.formData.offerAmount) || '-'
            },
            {
              key: 'buyer',
              label: 'Buyer',
              value: data.formData.buyer_1_name
            },
            {
              key: clsx('status', { [data.status.toLocaleLowerCase()]: true }),
              label: 'Status',
              value: data.status
            },
            {
              key: 'date',
              label: 'Listing Date',
              value: dayjs.unix(data.created).format('MM/DD/YYYY')
            },
            {
              key: 'download',
              value: !isMobile && hasLink && (
                <a
                  href={data.link}
                  target="_blank"
                  rel="noreferrer"
                  className="download-link"
                  onClick={e => e.stopPropagation()}>
                  <Icon name="download" size={12} />
                </a>
              )
            }
          ]}
        />
      }>
      <OfferDetails data={data} {...props} />
    </StyledAccordion>
  );
};

export default OffersTableItem;

const StyledAccordion = styled(Accordion)`
  width: 100%;
  .label {
    width: 100%;
  }
  .accordion-content {
    margin: 0 0 16px 24px;
  }
  .table-header {
    &-info__item {
      padding: 0 6px;
      &.listing {
        flex: 2;
        margin-right: -15px;
      }
      &.status {
        &.active {
          color: ${props => props.theme.colors.blue};
        }
        &.accepted {
          color: ${props => props.theme.colors.lightGreen};
        }
        &.rejected {
          color: ${props => props.theme.colors.red};
        }
      }
      .download-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin-left: auto;
        background: ${props => props.theme.colors.pink};
        border-radius: ${props => props.theme.misc.borderRadius};
        .icon {
          fill: ${props => props.theme.colors.red};
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .accordion-head {
      align-items: flex-start;
      .icon-plus-mini {
        margin-top: 7px;
      }
    }
    .table-header-info {
      .label {
        width: 50%;
      }
      .listing {
        .label {
          width: auto;
        }
      }
    }
  }
`;
