import React from 'react';

import styled from '@emotion/styled';
import { formatCurrency } from 'utils/rifmFormatters';
import { Button } from 'components';

interface OfferDetailsProps {
  data: WorkspaceOffer;
  openPopup: (value: { type: 'Accept' | 'Reject'; data: WorkspaceOffer }) => void;
}

const inspectionContigencyTexts = {
  asIs: 'As is',
  yes: 'Yes',
  no: 'No'
};

const OfferDetails: React.FC<OfferDetailsProps> = ({ data, openPopup }) => {
  const formData =
    typeof data.formData === 'string' ? JSON.parse(data.formData) : data.formData || {};

  return (
    <StyledOfferDetails className="offer-details">
      <div className="offer-info-wrapper">
        <h3 className="info-line">Information:</h3>
        {formData.type === 'agent' && (
          <div className="info-column">
            <p className="info-line">
              <span>Agent: </span> {formData.agent_name}
            </p>
            <p className="info-line">
              <span>Agent Email: </span> {formData.agent_email}
            </p>
            <p className="info-line">
              <span>Agent Phone: </span> {formData.agent_phone}
            </p>
            <p className="info-line">
              <span>Agent brokerage name: </span>
              {formData.agent_brokerage_name || 'N/A'}
            </p>
            <p className="info-line">
              <span>Agent licence number: </span>
              {formData.agent_licence_number || 'N/A'}
            </p>
          </div>
        )}
        <div className="info-column">
          <p className="info-line">
            <span>Buyer: </span>
            {data.name || formData.buyer_1_name || 'N/A'}
          </p>
          <p className="info-line">
            <span>Buyer Email: </span>
            {formData.buyer_1_email || 'N/A'}
          </p>
          <p className="info-line">
            <span>Buyer Phone: </span>
            {formData.buyer_1_phone || 'N/A'}
          </p>
          <p className="info-line">
            <span>Buyer visited property: </span>
            {formData.buyer_visited_property || 'N/A'}
          </p>
          {formData.buyer_2_name && (
            <>
              <p className="info-line">
                <span>Co-Buyer: </span>
                {formData.buyer_2_name || 'N/A'}
              </p>
              <p className="info-line">
                <span>Co-Buyer Email: </span>
                {formData.buyer_2_email || 'N/A'}
              </p>
            </>
          )}
        </div>
        <div className="info-column">
          {formData.company_name && (
            <>
              <p className="info-line">
                <span>Company Name: </span>
                {formData.company_name || 'N/A'}
              </p>
              <p className="info-line">
                <span>Company Email: </span>
                {formData.company_email || 'N/A'}
              </p>
            </>
          )}
        </div>
        <hr />
      </div>
      <div className="offer-info-wrapper">
        <h3 className="info-line">Finance:</h3>
        <div className="info-column">
          <p className="info-line">
            <span>Payment: </span>
            {formData.paymentMethod || 'N/A'}
          </p>
          <p className="info-line">
            <span>Down Payment: </span>
            {formatCurrency(formData.depositAmount) || 'N/A'}
          </p>
        </div>
        <div className="info-column">
          <p className="info-line">
            <span>Offer Amount: </span>
            {formData.offerAmount ? formatCurrency(formData.offerAmount) : 'N/A'}
          </p>
          <p className="info-line">
            <span>Escrow Deposit: </span>
            {formatCurrency(formData.escrowDeposit) || 'N/A'}
          </p>
        </div>
        <hr />
      </div>

      <h3 className="info-line">Deadline:</h3>
      <div className="info-column">
        <p className="info-line">
          <span>Date: </span>
          {formData.deadlineDate || 'N/A'}
        </p>
        <p className="info-line">
          <span>Attorney: </span>
          {formData.attorneyName || 'N/A'}
        </p>
        <p className="info-line">
          <span>Attorney Phone: </span>
          {formData.attorneyPhone || 'N/A'}
        </p>
        <p className="info-line">
          <span>Attorney Email: </span>
          {formData.attorneyEmail || 'N/A'}
        </p>
        <p className="info-line">
          <span>Inspection Contigency: </span>
          {formData.inspectionChoice ? inspectionContigencyTexts[formData.inspectionChoice] : 'N/A'}
        </p>
        <p className="info-line">
          <span>Home Warranty: </span>
          {formData.homeWarranty || 'N/A'}
        </p>
        {formData.homeWarranty && (
          <p className="info-line">
            <span>Home Warranty Price: </span>
            {formData.homeWarrantyPrice ? formatCurrency(formData.homeWarrantyPrice) : 'N/A'}
          </p>
        )}
        <p className="info-line">
          <span>Tax Proration: </span>
          {formData.taxProration || 'N/A'}
        </p>
      </div>
      <div className="info-column">
        <p className="info-line">
          <span>Lender: </span>
          {formData.lenderName || 'N/A'}
        </p>
        <p className="info-line">
          <span>Lender Phone: </span>
          {formData.lenderPhone || 'N/A'}
        </p>
        <p className="info-line">
          <span>Lender Email: </span>
          {formData.lenderEmail || 'N/A'}
        </p>
      </div>
      <p className="info-line btn-group">
        <Button
          secondary
          onClick={() => openPopup({ data, type: 'Reject' })}
          disabled={data.status === 'Rejected'}>
          Reject
        </Button>
        <Button
          onClick={() => openPopup({ data, type: 'Accept' })}
          disabled={data.status !== 'Active'}>
          Accept
        </Button>
      </p>
    </StyledOfferDetails>
  );
};

export default React.memo(OfferDetails);

const StyledOfferDetails = styled.div`
  margin-top: -16px;

  .info-column {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .info-line {
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    margin: 6px 0;
  }

  .btn-group {
    display: flex;
    justify-content: flex-end;

    .button + .button {
      margin-left: 10px;
    }
  }

  h3 {
    font-weight: 500;
    color: #000;
  }

  span {
    color: #545454;
    font-weight: 400;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .btn-group {
      justify-content: flex-end;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .info-column {
      width: 100%;
    }
  }
`;
