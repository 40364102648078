import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import OffersPage from 'views/OffersPage';
import Workspace from 'workspace';

const Offers = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/offers" component={OffersPage} />
      </Router>
    </Workspace>
  );
};

export default Offers;
