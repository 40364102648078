import React, { useContext } from 'react';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import { Button, Modal } from 'components';
import MainLayout from 'components/MainLayout';
import OffersControls from './OffersControls';
import OffersTableItem from './OffersTableItem';
import OffersContext, { OffersContextWrapper } from './OffersContext';

const OffersPage = () => {
  const {
    offers,
    offerPopupData,
    setOfferPopupData,
    rejectOrAcceptOffer
  } = useContext(OffersContext);
  const layout = useLayout();

  return (
    <MainLayout>
      <StyledOffersPage className="offers-page">
        <div className="offers-page__header">
          <h4 className="offers-page__title">Offers</h4>
          <OffersControls />
        </div>
        {(layout === 'desktop' || layout === 'tablet') && (
          <div className="offers-header">
            <div className="offers-header__item listing">Listing</div>
            <div className="offers-header__item">Listing Price</div>
            <div className="offers-header__item">Offer Amount</div>
            <div className="offers-header__item">Buyer</div>
            <div className="offers-header__item">Status</div>
            <div className="offers-header__item">Date</div>
            <div className="offers-header__item download">&nbsp;</div>
          </div>
        )}
        {offers.map(offer => (
          (<OffersTableItem key={offer.id} data={offer} openPopup={setOfferPopupData} />)
        )
        )}
      </StyledOffersPage>
      <StyledModal
        modalTitle={`${offerPopupData?.type} offer`}
        open={Boolean(offerPopupData)}
        onClose={() => setOfferPopupData(undefined)}
        className="delete-member">
        <p>Are you sure want to {offerPopupData?.type.toLocaleLowerCase()} the offer?</p>
        <Button secondary onClick={() => setOfferPopupData(undefined)}>
          Cancel
        </Button>
        <Button onClick={rejectOrAcceptOffer}>{offerPopupData?.type}</Button>
      </StyledModal>
    </MainLayout>
  );
};

export default props => (
  <OffersContextWrapper>
    <OffersPage {...props} />
  </OffersContextWrapper>
);

const StyledOffersPage = styled.div`
  .offers-page {
    &__header {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title {
      margin: 0;
    }
  }
  .offers-header {
    display: flex;
    &__item {
      flex: 1;
      padding: 12px 0 16px;
      font-size: 14px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
      &.listing {
        flex: 2;
      }
    }
  }
  table {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    border-collapse: collapse;

    th {
      font-weight: normal;
      color: #8b8b8b;
      fill: #8b8b8b;
      line-height: 16px;
      text-align: left;
      padding: 16px 0;
    }

    td {
      &:nth-of-type(1) {
        width: 32px;
        text-align: center;
      }
    }

    thead:first-of-type th {
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .button {
    width: 100%;
    height: 40px;
  }
  .button + .button {
    margin-top: 16px;
  }
`;
