import React, { useCallback, useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';

import notify from 'notify';
import { tryGetFirstError, downloadFile } from 'utils/requests';
import { getOffers } from './api';
import { changeOfferStatus } from 'workspace/api';
import { downloadDocument as downloadDocumentRequest } from 'api/documents';
import { DropdownOption } from 'components';
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

dayjs('2010-10-20').isSameOrAfter('2010-10-19', 'year');
interface WorkspaceContextValues {
  offers: WorkspaceOffer[];
  setOffers: React.Dispatch<React.SetStateAction<WorkspaceOffer[]>>;
  rejectOrAcceptOffer: (value) => void;
  offerPopupData?: OfferPopupData;
  setOfferPopupData: React.Dispatch<React.SetStateAction<OfferPopupData | undefined>>;
  statusFilter: string[];
  setStatusFilter: React.Dispatch<React.SetStateAction<string[]>>;
  statusOptions: DropdownOption[];
  searchFilter: string;
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
  dateFilter: DateFilter;
  setDateFilter: React.Dispatch<React.SetStateAction<DateFilter>>;
  downloadDocument: (value: number) => void;
}

type OfferPopupData = {
  type: 'Accept' | 'Reject';
  data: WorkspaceOffer;
};

type DateFilter = {
  start?: number;
  end?: number;
};

const statusOptions: DropdownOption[] = [
  { value: 'Active', text: 'Active' },
  { value: 'Accepted', text: 'Accepted' },
  { value: 'Rejected', text: 'Rejected' },
  { value: 'Not confirmed', text: 'Not confirmed' }
];

const WorkspaceContext = React.createContext({} as WorkspaceContextValues);

export const OffersContextWrapper = ({ children }) => {
  const [offers, setOffers] = useState<WorkspaceOffer[]>([]);
  const [offerPopupData, setOfferPopupData] = useState<OfferPopupData | undefined>();
  const [statusFilter, setStatusFilter] = useState<string[]>(
    statusOptions.map(option => option.value)
  );
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<DateFilter>({});

  const filteredOffers = useMemo(() => {
    let result = [...offers];
    if (dateFilter.start) {
      result = result.filter(item => {
        const startDate = dayjs.unix(dateFilter.start);
        return dayjs.unix(item.created).isSameOrAfter(startDate);
      });
    }
    if (dateFilter.end) {
      result = result.filter(item => {
        const endDate = dayjs.unix(dateFilter.end);
        return dayjs.unix(item.created).isSameOrBefore(endDate);
      });
    }
    if (searchFilter.trim()) {
      const lowerCaseSearchString = searchFilter.toLowerCase();

      result = result.filter(item => {
        const searchInString = [item.formData.propertyAddress, item.formData.name]
          .join()
          .toLocaleLowerCase();
        if (searchInString.includes(lowerCaseSearchString)) {
          return item;
        }
      });
    }
    if (statusFilter.length && statusFilter.length !== statusOptions.length) {
      result = result.filter(item => statusFilter.includes(item.status));
    }
    return result;
  }, [offers, statusFilter, statusOptions, searchFilter, dateFilter]);

  const fetchOffers = useCallback(async () => {
    try {
      const offers = await getOffers();
      setOffers(offers);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, []);

  const rejectOrAcceptOffer = async () => {
    try {
      const offerStatus = `${offerPopupData?.type}ed`;
      const offerPayload = { ...(offerPopupData?.data as WorkspaceOffer), status: offerStatus };
      await changeOfferStatus(offerPayload);
      setOfferPopupData(undefined);
      fetchOffers();
      notify(`Offer ${offerStatus.toLocaleLowerCase()}`);
    } catch (err) {
      notify(err.message);
    }
  };

  const downloadDocument = async (id: number) => {
    try {
      const { url, name } = await downloadDocumentRequest(id);
      downloadFile(url, name);
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  const values: WorkspaceContextValues = {
    offers: filteredOffers,
    setOffers,
    rejectOrAcceptOffer,
    offerPopupData,
    setOfferPopupData,
    statusFilter,
    setStatusFilter,
    statusOptions,
    searchFilter,
    setSearchFilter,
    dateFilter,
    setDateFilter,
    downloadDocument
  };
  return <WorkspaceContext.Provider value={values}>{children}</WorkspaceContext.Provider>;
};

export default WorkspaceContext;
